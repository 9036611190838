import React from 'react'
import Image from 'next/image'

import { data } from '@/data/team'

const OurTeam = () => {
    return (
        <section id="section-about" className={` section bg-section `}>
            <div className="container overflow-visible">
                <div className={`flex flex-col`}>
                    <div
                        className={`graphic animate__animated animate__slideInLeft w-full text-center opacity-0  ipad:w-full  ipad:opacity-100`}
                    >
                        <h2 className="text-interface-900">Our Team</h2>
                        <p className="mb-[42px] text-lg leading-8 text-interface-700 mobile:mb-0 mobile:leading-normal">
                            Step into the realm of brilliance, where innovation
                            meets passion, and creativity knows no bounds. At
                            Startrum, our team consists of visionaries, tech
                            wizards, and artistic minds who live and breathe
                            technology. We believe in the power of
                            collaboration, where ideas converge and ignite to
                            create wonders.
                        </p>
                    </div>
                    <div className="flex w-full items-center text-center lg:px-3 ipad:mt-14 ipad:w-full">
                        <div className="animate__animated animate__slideInRight opacity-0 ipad:opacity-100">
                            <ul className="grid grid-cols-4 gap-8 ipad:grid-cols-2 mobile:grid-cols-1 mobile:gap-10">
                                {data.teams.map((team, index) => (
                                    <li key={index}>
                                        {team.src && (
                                            <div className="m-auto mb-4 flex h-[164px] w-[164px] items-center justify-center overflow-hidden rounded-full ">
                                                <Image
                                                    src={team.src}
                                                    srcSet={team.retinaSrc}
                                                    alt={team.title}
                                                    width={164}
                                                    height={164}
                                                />
                                            </div>
                                        )}
                                        <div>
                                            {team.title && (
                                                <h3 className="mb-1 text-2xl font-bold text-interface-900">
                                                    {team.title}
                                                </h3>
                                            )}
                                            {team.subTitle && (
                                                <h6 className="mb-4 text-base font-medium text-orange mobile:mb-1">
                                                    {team.subTitle}
                                                </h6>
                                            )}
                                            {team.detail && (
                                                <p className="text-sm text-interface-700 mobile:text-base">
                                                    {team.detail}
                                                </p>
                                            )}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurTeam
