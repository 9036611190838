import React from 'react'
import Image from 'next/image'

const Services = () => {
    const industriesLIst = [
        {
            heading: 'Software Development',
            image: '/icons/scale.svg',
            description:
                'From innovative enterprise solutions to scalable web platforms, we craft custom software that fuels growth and drives efficiency, tailored to your unique business needs.',
        },
        {
            heading: 'Web App Development',
            image: '/icons/global.svg',
            description:
                'Elevate your digital presence with dynamic and responsive web applications. Our expert team leverages the latest technologies to build powerful, visually stunning web apps that engage and inspire.',
        },
        {
            heading: 'Mobile App Development',
            image: '/icons/phone.svg',
            description:
                'Embrace the mobile revolution and captivate your audience with immersive and user-friendly mobile applications for iOS and Android. We create seamless experiences that keep users coming back for more.',
        },
        {
            heading: 'AI & Machine Learning',
            image: '/icons/container.svg',
            description:
                'Our AI-powered solutions unlock valuable insights, automate processes, and empower businesses to make data-driven decisions with precision and efficiency.',
        },
    ]

    return (
        <section className={`section  `} id="section-industrial-served">
            <div className="container mx-auto overflow-visible">
                <div>
                    <div className="animate__animated animate__fadeInDown mb-12 opacity-0 md:text-center ipad:!opacity-100">
                        <h2 className="">Services</h2>
                        <p className="text-lg text-interface-700">
                            Unlock the full potential of your business with our
                            comprehensive suite of services:
                        </p>
                    </div>
                    <div className="flex gap-[80px] md:flex-col-reverse  mobile:gap-11">
                        <ul className="max-w-[454px] md:grid md:max-w-full md:grid-cols-2 md:gap-x-8 ipad:grid-cols-1">
                            {industriesLIst.map((item, index) => (
                                <li
                                    className={`item animate__animated animate__fadeInDown mb-8 flex items-start gap-5 mobile:gap-3`}
                                    key={index}
                                >
                                    <div>
                                        <div className="image flex h-[52px] w-[52px] items-center  justify-center rounded-[10px] bg-primary-500 p-[10px] mobile:mt-1 mobile:h-[40px] mobile:w-[40px]">
                                            <div>
                                                <Image
                                                    src={item.image}
                                                    alt={item.heading}
                                                    width={32}
                                                    height={28}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" mobile:w-full">
                                        <div>
                                            <h3 className="mb-2 text-2xl font-bold mobile:text-xl">
                                                {item.heading}
                                            </h3>
                                        </div>
                                        <p className="text-base ">
                                            {item.description}
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div>
                            <div className=" m w-[579px] md:mx-auto mobile:w-full">
                                <Image
                                    src="/services.svg"
                                    alt="services"
                                    width={579}
                                    height={710}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services
