import Image from 'next/image'

const About = () => {
    return (
        <section id="section-about" className={` section bg-section `}>
            <div className="container overflow-visible">
                <div className={`flex ipad:flex-col `}>
                    <div
                        className={`graphic animate__animated animate__slideInLeft w-3/6 opacity-0 md:pr-6 ipad:w-full  ipad:opacity-100  mobile:mx-auto mobile:pr-0`}
                    >
                        <Image
                            src="/about.png"
                            srcSet="/about@2x.png"
                            alt="about"
                            width={540}
                            height={710}
                            className="ipad:mx-auto ipad:w-full ipad:max-w-[600px]"
                        />
                    </div>
                    <div className="flex w-3/6 items-center pl-[50px]  lg:px-3 ipad:mt-14 ipad:w-full mobile:mt-6">
                        <div className="animate__animated animate__slideInRight opacity-0 ipad:opacity-100">
                            <h2 className="section-heading mb-8 mobile:mb-4">
                                About Us
                            </h2>
                            <p className="mb-6 text-xl text-interface-700 mobile:text-base">
                                Startrum is a software development company
                                specializing in building custom technology
                                solutions. With expertise in web, mobile, cloud
                                and AI applications development, along with
                                solution architecture, devops, UI/UX design,
                                security and scalability solutions.
                            </p>
                            <p className="text-xl text-interface-700 mobile:text-base">
                                Startrum provides a turnkey solution for
                                businesses and startups to onboard a technology
                                partner that can deliver a feature-rich
                                technology platform quickly, efficiently and in
                                a cost-effective manner. With over a decade of
                                experience, Startrum has established itself as a
                                leader in the software development space.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
