import React from 'react'
import Image from 'next/image'

const portfolio = [
    {
        image: '/portfolio/oreo.jpg',
        retinaImage: '/portfolio/oreo@2x.jpg',
        title: 'Oreo',
        width: 351,
        height: 630,
    },
    {
        image: '/portfolio/inbox.jpg',
        retinaImage: '/portfolio/inbox@2x.jpg',
        title: 'Inbox',
        width: 732,
        height: 300,
    },
    {
        image: '/portfolio/tobleron.jpg',
        retinaImage: '/portfolio/tobleron@2x.jpg',
        title: 'Tobleron',
        width: 352,
        height: 300,
    },
    {
        image: '/portfolio/gillette.jpg',
        retinaImage: '/portfolio/gillette@2x.jpg',
        title: 'Gillette',
        width: 351,
        height: 630,
    },
    {
        image: '/portfolio/thebodyshop.jpg',
        retinaImage: '/portfolio/thebodyshop@2x.jpg',
        title: 'Thebodyshop',
        width: 732,
        height: 630,
    },
    {
        image: '/portfolio/dentistro.jpg',
        retinaImage: '/portfolio/dentistro@2x.jpg',
        title: 'Dentistro',
        width: 351,
        height: 300,
    },
]

const portfolioContent = ` At Startrum, success is not just a destination; it&apos;s a way of life. Let us be the architects of your success story, and together, we will shape a future that defies boundaries and exceeds expectations.`

const Portfolio = () => {
    return (
        <section className={`section  pb-10`} id="section-portfolio">
            <div className="container">
                <h2 className="section-heading mb-4">Portfolio</h2>
                <p
                    className="mb-[42px] text-lg text-interface-700"
                    dangerouslySetInnerHTML={{ __html: portfolioContent }}
                ></p>
                <ul className="masonry-gallery">
                    {portfolio.map((item, index) => (
                        <li key={index}>
                            <Image
                                className="ml-auto"
                                src={item.image}
                                srcSet={item.retinaImage}
                                alt={item.title}
                                width={item.width}
                                height={item.height}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default Portfolio
