const Footer = () => {
    return (
        <div className="bg-interface-900 py-6 pb-6">
            <div className="container">
                <div className="text-center text-base text-[#F7E8D8]">
                    &copy; {new Date().getFullYear()} Startrum. All rights
                    reserved
                </div>
            </div>
        </div>
    )
}

export default Footer
