import Head from 'next/head'
import { Element, scroller } from 'react-scroll'
import React, { useEffect, useState } from 'react'

import Hero from '@/components/Hero'
import About from '@/components/About'
import Header from '@/components/Header'
import Layout from '@/components/Layout'
import Services from '@/components/Services'
import SuccessStory from '@/components/SuccessStory'
import Portfolio from '@/components/Portfolio'
import OurPartners from '@/components/OurPartners'
import OurTeam from '@/components/OurTeam'
import Contact from '@/components/Contact'
import Footer from '@/components/Footer'

const IndexPage = () => {
    const [active, setActive] = useState('homeSection')
    const [currentSection, setcurrentSection] = useState()
    const [isScrolledToScreenHeight, setIsScrolledToScreenHeight] =
        useState(false)
    const [isScrolledToInitialHeight, setIsScrolledToInitialHeight] =
        useState(false)

    const scrollToElement = (targetElement) => {
        setActive(targetElement)
        scroller.scrollTo(targetElement, {
            duration: 500,
            smooth: true,
            offset: 0, // Adjust the offset if needed
            delay: 0,
            isDynamic: true,
        })
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY
            const screenHeight = window.innerHeight
            const sections = document.querySelectorAll('.section')
            let activeSection = ''

            if (scrollPosition > 100) {
                setIsScrolledToInitialHeight(true)
            } else {
                setIsScrolledToInitialHeight(false)
            }

            if (scrollPosition >= screenHeight) {
                setIsScrolledToScreenHeight(true)
            } else {
                setIsScrolledToScreenHeight(false)
            }

            sections.forEach((section) => {
                const sectionTop = section.offsetTop
                const sectionBottom = sectionTop + section.offsetHeight

                if (
                    scrollPosition >= sectionTop - 300 &&
                    scrollPosition < sectionBottom
                ) {
                    if (!section.classList.contains('active')) {
                        section.classList.add('active')
                    }
                }
            })

            setcurrentSection(activeSection)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    return (
        <>
            <Head>
                <title>{`Home | ${process.env.NEXT_PUBLIC_APP_NAME}`}</title>
            </Head>
            <Layout>
                <div>
                    <div>
                        <Header
                            active={active}
                            fixedHeader={
                                isScrolledToInitialHeight ? 'fixed-header' : ''
                            }
                            stickHeader={
                                isScrolledToScreenHeight ? 'sticky-header' : ''
                            }
                            onClick={(event) => scrollToElement(event)}
                        />
                    </div>

                    {/* Hero Section */}
                    <div name="homeSection">
                        <Hero onClick={() => scrollToElement('aboutSection')} />
                    </div>
                    {/* About */}
                    <Element name="aboutSection">
                        <About />
                    </Element>
                    {/* Services */}
                    <Element name="ourServicesSection">
                        <Services />
                    </Element>
                    {/* Success Stories */}
                    <Element name="successStorySection">
                        <SuccessStory />
                    </Element>
                    {/* Our Partners */}
                    <Element name="teamSection">
                        <OurTeam />
                    </Element>
                    {/* Our Partners */}
                    <Element name="portfolioSection">
                        <Portfolio />
                    </Element>
                    {/* Our Partners */}
                    <Element>
                        <OurPartners />
                    </Element>
                    {/* Get In touch */}
                    <Element name="contactSection">
                        <Contact currentSection={currentSection} />
                    </Element>
                    {/* Footer */}
                    <Footer />
                </div>
            </Layout>
        </>
    )
}

export default IndexPage
