import React from 'react'
import { Link } from 'react-scroll'
const Navigation = ({ onClick, active, setIsMobileNav }) => {
    const menuItems = [
        // { text: 'Home', path: '/', name: 'homeSection', active: true },
        { text: 'About us', name: 'aboutSection' },
        {
            text: 'Services',
            name: 'ourServicesSection',
        },
        {
            text: 'Success story',
            name: 'successStorySection',
        },
        { text: 'Team', path: '/team', name: 'teamSection', offset: -40 },
        {
            text: 'Portfolio',
            name: 'portfolioSection',
        },
    ]

    return (
        <nav>
            <ul className="navigation">
                {menuItems.map((menuItem, index) => (
                    <li
                        key={index}
                        className={active === menuItem.name ? 'active' : ''}
                        onClick={() => {
                            onClick(menuItem.name)
                        }}
                    >
                        <Link
                            activeClass="active"
                            to={menuItem.name}
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                            onClick={() => {
                                setIsMobileNav(false)
                            }}
                        >
                            <span>{menuItem.text}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default Navigation
