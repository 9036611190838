import React from 'react'
import Image from 'next/image'

const SuccessStory = () => {
    return (
        <section id="section-about" className={` section bg-interface-900 `}>
            <div className="container overflow-visible">
                <div
                    className={`flex justify-between text-common-white ipad:flex-col`}
                >
                    <div
                        className={`graphic animate__animated animate__slideInLeft w-[479px] opacity-0 md:max-w-[300px] md:flex-shrink-0 md:pr-6 ipad:w-full  ipad:max-w-full  ipad:opacity-100`}
                    >
                        <Image
                            src="/success-story.png"
                            srcSet="/success-story@2x.png"
                            alt="about"
                            width={479}
                            height={390}
                            className="ipad:mx-auto ipad:w-full ipad:max-w-[600px]"
                        />
                    </div>
                    <div className="flex w-3/6 items-center pl-[10px] lg:px-3 md:w-full ipad:mt-14 ipad:w-full mobile:mt-10 mobile:p-0">
                        <div className="animate__animated animate__slideInRight opacity-0 ipad:opacity-100">
                            <h2 className="section-heading mb-4">
                                Success story
                            </h2>
                            <p className="mb-4 text-lg leading-8 text-section mobile:mb-6 mobile:text-base mobile:leading-normal">
                                Since our inception, we have embarked on a
                                remarkable journey, achieving significant
                                milestones along the way:
                            </p>
                            <div className="mb-10 flex gap-5 mobile:mb-6 mobile:flex-col">
                                <div className="flex w-full flex-col items-center justify-center mobile:flex-row">
                                    <span className="mb-5 font-raleway text-5xl mobile:mb-2 mobile:w-[110px] mobile:text-3xl">
                                        12+
                                    </span>
                                    <span className="flex w-full items-center justify-center rounded-[4px] bg-primary-500 px-2 py-1 font-roboto text-base font-bold text-interface-900 mobile:py-3">
                                        Years of Experience
                                    </span>
                                </div>
                                <div className="flex w-full flex-col items-center justify-center mobile:flex-row">
                                    <span className="mb-5 font-raleway text-5xl mobile:mb-2 mobile:w-[110px] mobile:text-3xl ">
                                        200+
                                    </span>
                                    <span className="flex w-full items-center justify-center rounded-[4px] bg-primary-500 px-2 py-1 font-roboto text-base font-bold text-interface-900 mobile:py-3">
                                        Projects Complete
                                    </span>
                                </div>
                                <div className="flex w-full flex-col items-center justify-center mobile:flex-row">
                                    <span className="mb-5 font-raleway text-5xl mobile:mb-2 mobile:w-[110px] mobile:text-3xl ">
                                        100+
                                    </span>
                                    <span className="flex w-full items-center justify-center rounded-[4px] bg-primary-500 px-2 py-1 font-roboto text-base font-bold text-interface-900 mobile:py-3">
                                        Clients
                                    </span>
                                </div>
                            </div>
                            <p className="text-lg font-medium leading-8 text-section mobile:text-base mobile:leading-normal">
                                Our apps have touched the lives of over 100
                                million users, transforming their digital
                                experiences and creating lasting impressions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SuccessStory
