import React, { useState } from 'react'
import { Link } from 'react-scroll'
import Image from 'next/image'
import Navigation from '../components/Navigation'
import Logo from '../components/Logo'
import Button from '../components/Button'

const Header = ({ onClick, fixedHeader, stickHeader, active }) => {
    const [isMobileNav, setIsMobileNav] = useState(false)

    const toggleHeaderMenu = () => {
        setIsMobileNav((isActive) => {
            return !isActive
        })
    }

    return (
        <>
            <header className={`header ${fixedHeader} ${stickHeader} relative`}>
                <div className="container ipad:!overflow-visible">
                    <div className="inner w-full items-center justify-between">
                        <div
                            className={`w-[171px] flex-shrink-0 cursor-pointer ipad:w-[150px] mobile:w-[130px] ${
                                fixedHeader ? 'mobile:hidden' : ''
                            }`}
                        >
                            <Link
                                to="homeSection"
                                spy={true}
                                smooth={true}
                                offset={0}
                                duration={500}
                            >
                                <Logo />
                            </Link>
                        </div>

                        <div className="right-column">
                            <div
                                className={`header-nav ${
                                    isMobileNav
                                        ? 'mob-nav-menu menu-active'
                                        : ''
                                } `}
                            >
                                <div className="nav-wrapper">
                                    {/* Navigation */}
                                    <Navigation
                                        onClick={onClick}
                                        active={active}
                                        setIsMobileNav={setIsMobileNav}
                                    />
                                </div>
                                <div className="contact-btn w-[105px] flex-shrink-0">
                                    <Button
                                        text="Contact"
                                        onClick={() => {
                                            setIsMobileNav(false)
                                            onClick('contactSection')
                                        }}
                                    />
                                </div>
                            </div>

                            {/* mobile menu */}
                            <div
                                className="menu-icon hidden ipad:block"
                                onClick={toggleHeaderMenu}
                            >
                                <Image
                                    src="/icons/menu.svg"
                                    alt=""
                                    width={32}
                                    height={32}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
