import React from 'react'
import Image from 'next/image'
import { Link } from 'react-scroll'
import Button from '@/components/Button'

const Hero = () => {
    return (
        <section className="hero-section section active ">
            <div className="content ">
                <div className="container relative overflow-visible">
                    <div>
                        <div className="animate__animated animate__repeat-1 animate__slideInLeft animate__delay-1s max-w-[463px] pt-6 ipad:max-w-full">
                            <h1>
                                Empower Your <br /> Digital Journey with
                                <br />
                                <span> AI-Powered</span>
                                <br />
                                Solutions
                            </h1>
                            <p>
                                Revolutionizing Businesses through Innovative
                                Software, App Development, and AI & Machine
                                Learning Services.
                            </p>
                            <div className="w-[183px] mobile:w-[150px]">
                                <Link
                                    to="section-get-in-touch"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={500}
                                >
                                    <Button
                                        variant="secondary"
                                        size="lg"
                                        text="Contact Us"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="hero-illustration animate__animated animate__repeat-1 animate__delay-1s animate__slideInRight max-w-[579px] md:max-w-[450px] ipad:mt-8 ipad:!opacity-30 mobile:hidden">
                            <Image
                                src="/hero-illustration.svg"
                                className="primary-logo"
                                alt="Empower Your Digital Journey with AI-Powered Solutions"
                                width={579}
                                height={714}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero
