export const data = {
    teams: [
        {
            src: '/team/ahmed-hashim.png',
            retinaSrc: '/team/ahmed-hashim@2x.png',
            title: 'Ahmed Hashim',
            subTitle: 'Co-Founder & CEO',
            detail: 'Rich experience in managing teams that design and build large-scale online digital marketing campaigns.',
        },
        {
            src: '/team/arsalan-akhtar.png',
            retinaSrc: '/team/arsalan-akhtar@2x.png',
            title: 'Arsalan Akhtar',
            subTitle: 'Co-Founder & President',
            detail: 'Creative, results driven technology leader with 18+ years of proven technical operations experience.',
        },
        {
            src: '/team/usama-tahir.png',
            retinaSrc: '/team/usama-tahir@2x.png',
            title: 'Usama Tahir',
            subTitle: 'SVP of Engineering',
            detail: 'A DevOps engineer with the expertise in setting up complex architectures and technical processes with the highest of security and scalability.',
        },
        {
            src: '/team/rafay-ahmed.png',
            retinaSrc: '/team/rafay-ahmed@2x.png',
            title: 'Rafay Ahmed',
            subTitle: 'VP of Engineering',
            detail: 'Passionate developer and leader of an extensive and diverse team having hands on experience in Web Development.',
        },
    ],
}
