/* eslint-disable */
import React, { useState } from 'react'
import Image from 'next/image'
import emailjs from 'emailjs-com'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// import validationSchema from '@/validations/contact'

const Contact = ({ currentSection }) => {
    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState(false)
    const email = 'Email: hr@startrum.com'
    const initialValues = {
        fullName: '',
        workEmail: '',
        message: '',
    }
    const validationSchema = Yup.object({
        fullName: Yup.string().required(),
        workEmail: Yup.string().email().required(),
        message: Yup.string().required(),
    })

    const handleSubmit = async (values) => {
        setLoader(true)
        try {
            const templateParams = {
                from_name: `${values.firstName} ${values.lastName}`,
                from_email: values.workEmail,
                phone_number: values.phoneNumber,
                message: values.message,
            }

            // Send the email using emailjs
            const response = await emailjs.send(
                process.env.NEXT_PUBLIC_EMAIL_SERVICE_ID,
                process.env.NEXT_PUBLIC_EMAIL_TEMPLATE_ID,
                templateParams,
                process.env.NEXT_PUBLIC_EMAIL_API_KEY
            )

            if (response.status === 200) {
                // Clear the form after successful submission
                formik.resetForm()
                setLoader(false)
                setSuccess(true)
                const timer = setTimeout(() => {
                    setSuccess(false)
                }, 2000)
                return () => clearTimeout(timer)
            } else {
                setLoader(false)
            }
        } catch (error) {
            // eslint-disable-next-line
            console.error('Error:', error)
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
    })

    return (
        <section
            id="section-get-in-touch"
            className={`section bg-section text-interface-900 ${currentSection}`}
        >
            <div className="container">
                <div>
                    <h2 className="mb-4">Contact us</h2>
                    <p className="mb-[42px] text-lg text-interface-700">
                        Got a project? Let's talk! Give us a call, write us an
                        email or fill out the form below.
                    </p>
                </div>
                <div className="flex flex-row-reverse gap-16 ipad:block ipad:gap-0">
                    <div className="w-[404px] flex-shrink-0 ipad:mb-11 ipad:w-full ipad:border-b ipad:border-primary-900">
                        <div className="ipad:mb-10">
                            <h5 className="mb-4 text-lg font-semibold text-interface-900">
                                Looking for a job? Send your CV at:
                            </h5>
                            <ul>
                                <li className="mb-4 flex ">
                                    <i className="mr-3 h-6 w-6 flex-shrink-0">
                                        <Image
                                            src="/icons/email-icon.svg"
                                            alt="email"
                                            width={24}
                                            height={24}
                                        />
                                    </i>
                                    <span>
                                        Email:
                                        <a
                                            className="ml-2 text-interface-900 underline"
                                            href={`mailto:${email}`}
                                        >
                                            {email}
                                        </a>
                                    </span>
                                </li>
                                <li className="mb-0 flex text-interface-900">
                                    <i className="mr-3 h-6 w-6 flex-shrink-0">
                                        <Image
                                            src="/icons/location-icon.svg"
                                            alt="location"
                                            width={30}
                                            height={30}
                                        />
                                    </i>
                                    <span>
                                        D/5, Mezzanine Floor, Block 7/8, Central
                                        Commercial Area, CHS, Block A, Bangalore
                                        Town, Karachi, Pakistan
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* Contact form */}
                    <div className="w-full">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid grid-cols-1 gap-y-0">
                                <div className="form-group">
                                    <label>Full name</label>
                                    <input
                                        className={`form-control ${
                                            formik.touched.fullName &&
                                            formik.errors.fullName
                                                ? 'error'
                                                : ''
                                        }`}
                                        type="text"
                                        id="fullName"
                                        placeholder="Enter your full name"
                                        {...formik.getFieldProps('fullName')}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        className={`form-control ${
                                            formik.touched.workEmail &&
                                            formik.errors.workEmail
                                                ? 'error'
                                                : ''
                                        }`}
                                        type="email"
                                        id="workEmail"
                                        placeholder="Enter your email address"
                                        {...formik.getFieldProps('workEmail')}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Message</label>
                                    <textarea
                                        className={`form-control ${
                                            formik.touched.message &&
                                            formik.errors.message
                                                ? 'error'
                                                : ''
                                        }`}
                                        id="message"
                                        placeholder="Start typing your message"
                                        {...formik.getFieldProps('message')}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center mobile:flex-col">
                                <div className="mr-3 h-[56px] w-[156px] flex-shrink-0 mobile:m-0 mobile:w-full">
                                    <button
                                        className="btn h-[56px] w-[156px] uppercase"
                                        type="submit"
                                    >
                                        {!loader && <span>Submit</span>}
                                        {loader && (
                                            <div>
                                                <div className="loader">
                                                    Loading...
                                                </div>
                                            </div>
                                        )}
                                    </button>
                                </div>
                                <div
                                    className={` h-[52px] w-full items-center rounded-md bg-[#22c55e] px-4 py-1.5 text-base text-common-white transition-all  mobile:mt-4 ${
                                        success ? 'flex' : 'hidden'
                                    }`}
                                >
                                    Email sent Successfully
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
