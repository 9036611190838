import React from 'react'
import Image from 'next/image'

const OurPartners = () => {
    const logos = [
        {
            name: 'UBL',
            src: '/our-partners/ubl.png',
            retinaSrc: '/our-partners/ubl@2x.png',
        },
        {
            name: 'Honda',
            src: '/our-partners/honda.png',
            retinaSrc: '/our-partners/honda@2x.png',
        },
        {
            name: 'Gillette',
            src: '/our-partners/gillette.png',
            retinaSrc: '/our-partners/gillette@2x.png',
        },
        {
            name: 'Cadbury',
            src: '/our-partners/cadbury.png',
            retinaSrc: '/our-partners/cadbury@2x.png',
        },
        {
            name: 'Inbox',
            src: '/our-partners/inbox.png',
            retinaSrc: '/our-partners/inbox@2x.png',
        },

        {
            name: 'Cygnis Media',
            src: '/our-partners/cygnis.png',
            retinaSrc: '/our-partners/cygnis@2x.png',
        },
    ]
    return (
        <section id="section-our-partners" className={`section pt-0 `}>
            <div className="container">
                <div className="flex flex-col items-center gap-7 mobile:flex-col mobile:gap-0">
                    <div className=" mobile:w-full">
                        <p className="mx-auto max-w-[1000px] px-4 text-center text-2xl font-medium text-interface-700 mobile:mb-5 mobile:text-lg">
                            At Startrum, we have had the privilege of working
                            with a diverse range of clients, spanning various
                            industries and sectors.
                        </p>
                    </div>
                    <div className="w-full mobile:w-full">
                        <ul className="grid grid-cols-6 mobile:grid-cols-2 mobile:gap-4">
                            {logos.map((logo, index) => (
                                <li key={index}>
                                    <a
                                        href={logo.path}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Image
                                            src={logo.src}
                                            srcSet={logo.retinaSrc}
                                            alt={logo.name}
                                            width={160}
                                            height={90}
                                        />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurPartners
